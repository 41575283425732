import React, { Component, PureComponent } from 'react';
import './index.scss'
import {NavLink, Link, Redirect} from 'react-router-dom'
import AboutUsPage from './About'

import mobilecheck from './mobilecheck'

const IS_MOBILE = mobilecheck()

export const customOrderAppPaths = ["/", "/menu", "/order"]

export {AboutUsPage as about}

function PDFMenuRedirect () {
  return (
    <Redirect to="/menu" />
  )
}

export const pages = [
  {path: "/order", label: "Order"},
  {path: "/menu", label: "Menu"},
  {path: "mailto:info@agrilledcheeseco.com", label: "Email", external: true},
  {path: "/final_melt_it_menu.pdf", hideFromNav: true, component: PDFMenuRedirect}
  // {path: "/about", label: "Our Concept"},
  // {path: "/about", label: "Catering"}
]

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav className="pages-nav">
      <ul>
        {pages.filter(p => !p.hideFromNav).map(({path, label, external}) => {
          return (
            <li>
              {external ?
                <a className="page-link" href={path}>{label}</a> :
                <NavLink className="page-link" exact to={path}>{label}</NavLink>}
            </li>
          )
        })}
      </ul>
    </nav>
  )}
}

class CoralCafe extends Component {
  render() {
    return (
      <div className="coralcafe">
      </div>
    );
  }
}

export class Header extends Component {
  render() {
    return (
      <div className="landing-page-wrapper">
        <div className="landing-page">
          <div className="text">
            <h1>Melt It!</h1>
            <p className="catch-phrase">A Grilled Cheese Co.</p>

            <Link to="/order" className="order-online-link">Order Pickup / Delivery</Link>

          </div>
          <img className="badge" alt="as seen in yahoo.com's best grilled cheese sandwiches in the US!" src="https://afag.imgix.net/melt-it-grilled-cheese/badge.png?auto=format" alt=""/>








            {/* <article className="landing-section award">
              <div className="section-header">
                <div className="text">
                  <img src="https://d2abh92eaqfsaa.cloudfront.net/image/upload/v1551992456/Artboard_8latimes_aqnxze.png" alt=""/>
                </div>
              </div>
            </article> */}





        </div>
      </div>
    );
  }
}

export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="ordering-header-wrapper">
        <div className="ordering-header">

          <div
            className="text"
             >
            <h2>Start your order below</h2>
            <p></p>
          </div>
        </div>
      </div>
    );
  }
}

export default CoralCafe;
