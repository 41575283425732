
import * as Components from "./Meltit"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "meltit"
}

